import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { rem } from "polished"
// import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

// import { ReactComponent as SvgArrowRight } from "../../assets/images/icons/arrow-right.svg"

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  > div {
    height: 65vh;
    min-height: ${rem(320)};
    max-height: ${rem(560)};
    position: relative;
    overflow: hidden;
    border-radius: ${rem(8)};
    background-color: ${({ theme }) => theme.colors.brown};

    @media ${({ theme }) => theme.mq.mediumDown} {
      max-height: ${rem(360)};
    }

    @media ${({ theme }) => theme.mq.xsmallDown} {
      max-height: ${rem(280)};
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.3)
      );
    }
  }
`

const Media = styled.div`
  > video,
  > .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: 1;
    top: 0;
    left: 0;
  }

  > video {
    object-fit: cover;
  }
`

const Heading = styled.h1`
  ${({ theme }) => theme.fonts.set(`secondary`, `bold`)}

  margin-bottom: 0.4em;
  line-height: 1.1;
  font-size: 3.6em;
`

const Text = styled.div`
  width: 80%;
  max-width: ${rem(640)};
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  a {
    ${({ theme }) => theme.fonts.set(`primary`, `semibold`)};

    color: ${({ theme }) => theme.colors.creamLight};

    &:hover {
      color: ${({ theme }) => theme.colors.white};

      svg {
        transform: translateX(0.3em);
      }
    }

    &:active {
      opacity: 0.6;
    }

    span {
      margin-right: 0.25em;
    }

    svg {
      width: 1.4em;
      height: 1.4em;
      margin-top: -0.1em;
      transition: transform 0.2s ${({ theme }) => theme.easings.default};
    }
  }
`

const MediaBlock = ({ data, headingLevel = 2, ...props }) => {
  if (!data) return null
  return (
    <Container {...props}>
      <div>
        <Media>
          {data.image && (
            <GatsbyImage
              image={data.image.childImageSharp.gatsbyImageData}
              alt={data.title}
            />
          )}

          {data.video && (
            <video
              src={data.video}
              preload="auto"
              playsInline
              autoPlay
              loop
              muted
            />
          )}
        </Media>

        <Text>
          {data.title && (
            <Heading
              as={`h${headingLevel}`}
              dangerouslySetInnerHTML={{ __html: data.title }}
            />
          )}

          {/* {data.cta && data.cta.url && data.cta.title && (
            <Link to={data.cta.url}>
              <span dangerouslySetInnerHTML={{ __html: data.cta.title }} />

              <SvgArrowRight aria-hidden="true" />
            </Link>
          )} */}
        </Text>
      </div>
    </Container>
  )
}

export default MediaBlock
