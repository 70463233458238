import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { rem } from "polished"

import EventsCarousel from "../events-carousel"
import HexagonIcon from "../hexagon-icon"
import { Label2 } from "../styled/label"
import { ReactComponent as SvgCalendar } from "../../assets/images/icons/calendar.svg"

const EventsHighlights = ({ data, headingLevel = 2, visibleSlides = 3 }) => {
  const { config } = useStaticQuery(graphql`
    query {
      config: configJson {
        pages {
          events {
            title
            slug
          }
        }
      }
    }
  `)

  if (!data.length) return null

  return (
    <Container>
      <Heading as={`h${headingLevel}`}>
        <HexagonIcon
          iconSvg={<SvgCalendar />}
          className="-icon"
          aria-hidden="true"
          iconColor="creamLight"
        />

        <Label2
          dangerouslySetInnerHTML={{ __html: config.pages.events.title }}
        />
      </Heading>

      <EventsCarousel
        data={data}
        visibleSlides={visibleSlides}
        cardHeadingLevel={Math.min(headingLevel + 1, 6)}
      />
    </Container>
  )
}

export default EventsHighlights

const Container = styled.section`
  ${({ theme }) => theme.grid.container()}

  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  @media ${({ theme }) => theme.mq.mediumDown} {
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
  }
`

const Heading = styled.h2`
  margin-bottom: ${rem(40)};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.metallic};

  @media ${({ theme }) => theme.mq.mediumDown} {
    margin-bottom: ${rem(20)};
  }

  > .-icon {
    width: 3em;
    height: 3em;
    margin-right: 0.75em;
  }
`
