import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { rem } from "polished"
import { Link } from "@outdoormap/gatsby-plugin-brand-sites"

import { Heading4 } from "../styled/heading"
import { ReactComponent as SvgCalendar } from "../../assets/images/icons/calendar.svg"

const EventCard = ({
  data: {
    title,
    image,
    date,
    dateFormatted,
    dateTimeFormatted,
    dateTimeMark,
    path,
  },
  titleTag = `h2`,
  ...props
}) => {
  return (
    <Container {...props}>
      <Link to={path}>
        <Image>
          {image && <GatsbyImage image={image} alt={title} />}

          <SvgCalendar aria-hidden="true" />
        </Image>

        {title && (
          <Title as={titleTag} dangerouslySetInnerHTML={{ __html: title }} />
        )}

        {date && (
          <Date
            datetime={date}
            dangerouslySetInnerHTML={{
              __html:
                dateTimeMark == `000000` ? dateFormatted : dateTimeFormatted,
            }}
          />
        )}
      </Link>
    </Container>
  )
}

export default EventCard

const Image = styled.figure`
  width: 100%;
  height: 15em;
  margin-bottom: 1em;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: ${rem(5)};
  box-shadow: 0 ${rem(2)} ${rem(4)} rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ${({ theme }) => theme.easings.default};

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  svg {
    width: ${rem(40)};
    height: ${rem(40)};
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    opacity: 0.5;
  }
`

const Date = styled.time`
  margin-top: 0.5em;
  display: block;
  font-size: 0.875em;
  color: ${({ theme }) => theme.colors.metallic};
`

const Title = styled(Heading4)`
  width: 80%;
`

const Container = styled.article`
  width: 100%;

  a {
    display: block;

    &:hover {
      ${Image} {
        opacity: 0.8;
      }

      ${Title} {
        color: ${({ theme }) => theme.colors.gold};
      }
    }

    &:active {
      ${Image} {
        transition: none;
        opacity: 0.6;
      }
    }
  }
`
